<template>
  <div class="organize-tab">
    <div class="custom-card">
      <div>
        <b-alert variant="primary" show>
          <span class="custom-text">
            <b>Grouping is optional.</b> If you would like to create groups for your featured endorsements, you simply need to click "Add New Group". Give your group a name and click "Save". You can now click and drag any of your featured endorsements into this group. Repete this process for additional groups.
          </span>
        </b-alert>

        <div>
          <b-button size="lg" @click="addNewGroup">ADD NEW GROUP</b-button>
        </div>
      </div>

      <draggable v-model="allGroups" tag="div">
        <div
          v-for="(group, idx) in getStatementEndorsements(
            allGroups,
            statementId
          )"
          :key="idx"
          class="sortable"
        >
          <div class="mt-4 mb-3">
            <b-icon icon="list"></b-icon>
            <span class="h4 text-uppercase ml-4 mr-4"
              ><strong>{{ group.title }}</strong></span
            >
            <span class="btn-wrapper">
                <b-button v-if="group.id > 0"
                          variant="link"
                          @click="editGroup(group)">Edit Name</b-button>

                <span v-if="group.id > 0" class="text-muted mx-1">|</span>

                <b-button v-if="group.id > 0"
                          variant="link"
                          @click="deleteGroup(group)">Delete Group</b-button>
            </span>
            <!-- <b-icon
              :icon="group.visible ? `chevron-down` : 'chevron-up'"
              class="mb-1 custom-icon float-right"
              v-b-toggle="`collapse-${group.id}`"
            ></b-icon> -->
          </div>
          <draggable
            :id="group.title"
            :list="group.items"
            class="list-group"
            draggable=".item"
            group="a"
            @change="changeOrder"
          >
            <div
              class="list-group-item item"
              v-for="element in group.items"
              :key="element.publicEndorsementId"
            >
              <b-row>
                <b-col cols="1" sm="1">
                  <b-icon
                    icon="arrows-move"
                    aria-hidden="true"
                    class="text-muted"
                  ></b-icon>
                </b-col>
                <b-col cols="11" sm="4">{{
                  `${element.publicEndorsement.firstName} ${element.publicEndorsement.middleName} ${element.publicEndorsement.lastName}`
                }}</b-col>
                <b-col cols="12" sm="3">
                  <span
                    class="key-color key-orange"
                    v-if="colorCheck(1, element.publicEndorsement.position)"
                  ></span>
                  <span
                    class="key-color key-red"
                    v-if="colorCheck(2, element.publicEndorsement.position)"
                  ></span>
                  <span
                    class="key-color key-green"
                    v-if="colorCheck(3, element.publicEndorsement.position)"
                  ></span>
                  <span
                    class="key-color key-yellow"
                    v-if="colorCheck(4, element.publicEndorsement.position)"
                  ></span>
                  <span
                    class="key-color key-purple"
                    v-if="colorCheck(5, element.publicEndorsement.position)"
                  ></span>
                </b-col>
                <b-col cols="12" sm="4">
                  <b-row>
                    <b-col>
                      <span
                        :class="`featured-label float-right text-right text-${
                          element.isFeatured ? 'black' : 'secondary'
                        }`"
                      >
                        Featured
                      </span>
                    </b-col>
                    <b-col cols="3">
                      <b-form-checkbox
                        switch
                        size="lg"
                        class="float-right"
                        v-model="element.isFeatured"
                        @change="toggleFeatured(element)"
                      ></b-form-checkbox
                    ></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </draggable>
          <!-- <b-collapse :id="`collapse-${group.id}`"> </b-collapse> -->
        </div>
      </draggable>

      <hr />
      <div>
        <b-row class="footer-btn">
          <b-col>
            <b-button
              variant="outline-secondary"
              align-self="left"
              size="lg"
              @click="$emit('prev')"
            >
              <b-icon icon="arrow-left"></b-icon>
              Back
            </b-button>
          </b-col>
          <b-col>
            <b-button variant="primary" size="lg" @click="updateGroup(true)" class="float-right"
              >Next
              <b-icon icon="arrow-right"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal id="modal-group-title" :title="modalTitle" size="sm" hide-footer>
      <b-form @submit="onGroupSubmit">
        <b-form-group label="Group Name">
          <b-form-input
            v-model="editGroupItem.title"
            type="text"
            placeholder="Enter Group Name"
            required
          ></b-form-input>
        </b-form-group>
        <b-row class="mt-3">
          <b-col class="text-left"> </b-col>
          <b-col class="text-right">
            <b-button type="submit" variant="primary">Save</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as CandidateService from "@/services/CandidateService2";
export default {
  components: {
    draggable,
  },
  props: {
    statementId: {
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      isNewGroup: false,
      editGroupItem: {
        id: 0,
        title: "",
      },
      modalTitle: "Add Group",
      show: true,
    };
  },
  computed: {
    // allGroups() {
    //   return this.$store.state.candidates.candidate.endorsementGroups
    // },
    allGroups: {
      get() {
        return this.$store.state.candidates.candidate.endorsementGroups;
      },
      set(value) {
        console.log(value);
        this.$store.dispatch("candidates/updateEndorsementGroup", value);
      },
    },
  },
  async mounted() {},
  methods: {
    addNewGroup() {
      this.modalTitle = "Add New Group";
      this.editGroupItem = {
        id: 0,
        title: "",
        endorsementStatementId: this.statementId,
      };
      this.isNewGroup = true;
      this.$bvModal.show("modal-group-title");
    },
    saveGroup() {
      this.$store.dispatch("candidates/updateEndorsementGroup", this.allGroups);
      this.editGroupItem = null;
    },
    editGroup(group) {
      this.editGroupItem = group;
      this.modalTitle = "Edit Group";
      this.isNewGroup = false;
      this.$bvModal.show("modal-group-title");
    },
    getStatementEndorsements(items, statementId) {
      // console.log(items);
      // console.log(statementId);
      return items.filter(function (item) {
        return item.endorsementStatementId == statementId;
      });
    },
    toggleFeatured(item) {
      console.log(item);
      const data = {
        groupId: item.endorsementGroupId,
        publicEndorsementId: item.publicEndorsementId,
        statementId: item.endorsementStatementId,
        userId: this.$store.state.candidates.candidate.userId,
      };
      this.$store.dispatch(
        "candidates/removeFeaturedEndorsementGroupItem",
        data
      );
    },
    deleteGroup(group) {
      this.$bvModal
        .msgBoxConfirm("Are you sure want to delete this group?", {
          title: "Please Confirm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch("candidates/deleteEndorsementGroup", {
              id: group.id,
              userId: this.$store.state.candidates.candidate.userId,
            });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    async onGroupSubmit(event) {
      event.preventDefault();
      if (this.isNewGroup) {
        this.$store.dispatch("candidates/addNewEndorsementGroup", {
          groupTitle: this.editGroupItem.title,
          candidateId: this.$store.state.candidates.candidate.id,
          endorsementStatementId: this.statementId,
        });
      } else {
        this.$store.dispatch(
          "candidates/updateEndorsementGroup",
          this.allGroups
        );
      }

      this.$bvModal.hide("modal-group-title");
    },
    changeOrder(e) {
      if (e.added || e.moved) {
        this.$store.dispatch(
          "candidates/updateEndorsementGroup",
          this.allGroups
        );
      }
    },
    updateGroup(next = false) {
      if (next) {
        this.$emit("next");
      }
    },
    colorCheck(id, positions) {
      let pos = positions.split(",");
      pos = pos.map((x) => {
        return parseInt(x);
      });
      var hasPosition = pos.some((x) => x === id);
      return hasPosition;
    },
  },
  created() {},
};
</script>

<style lang="scss">
  .organize-tab {
    .featured-label {
      font-size: 12px;
      line-height: 30px;
      text-transform: uppercase;
    }

    .btn-link {
      font-size: 14px;
    }

    .btn-lnk:focus {
      outline: none !important;
    }

    .list-group-item {
      margin-bottom: 15px;
    }

    .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-radius: 3px;
    }

    @media (max-width: 991px) {
      .list-group-item {
        .custom-switch {
          right: -15px;
        }
      }
    }

    @media (max-width: 575px) {
      .list-group-item {
        .custom-switch {
          right: 0;
        }
      }

      .btn-wrapper {
        display: block;
        margin-top: 10px;

        .btn-link {
          padding: 0;
        }
      }

      .footer-btn {
        .btn {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
</style>
